import request from '@/api/request'

export const DICT = 'dict'
export const MENU = 'menu'
export const OPERATION_LOG = 'operationLog'
export const APP_CONFIG = 'appConfig'
export const MP_CONFIG = 'mpConfig'
export const WX_PAY_CONFIG = 'wxPayConfig'
export const ALI_PAY_CONFIG = 'aliPayConfig'
export const PLATFORM_CONFIG = 'platformConfig'
export const ANNOUNCEMENT = 'announcement'
export const SUBSCRIBE_MSG = 'subscribeMsg'
export const PUSH_MSG = 'pushMsg'
export const SERVER_INFO = 'serverInfo'
export const DATA_CLEAR = 'dataClear'
export const DATA_CACHE = 'dataCache'
export const FACE_ID_RECHARGE = 'faceIdRecharge'
export const FACE_ID_ACCOUNT = 'faceIdAccount'
export const FACE_ID_CONFIG = 'faceIdConfig'
export const ESIGN_CONFIG = 'esignConfig'
export const VEHICLE_INTEGRATION_CONFIG = 'vehicleIntegrationConfig'
export const SEAL = 'seal'
export const SIGN_TASK = 'signTask'
export const BILL_ACCOUNT = 'billAccount'
export const WORK_TEMPLATE = 'workTemplate'
export const TEMPLATE_BINDING = 'templateBinding'
export const BUSINESS_BINDING = 'businessBinding'
export const SUBSCRIBE = 'subscribe'
export const SYS_NOTICE = 'sysNotice'
export const USER_SYS_NOTICE = 'userSysNotice'

/**
 * 用户菜单树
 * @returns {*}
 */
export function findUserMenuTree(params) {
    return request.get(`api/v1/system/${MENU}/user/tree`, { params });
}

/**
 * 分页查询
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByPage(entityName, data) {
    return request.post(`api/v1/system/${entityName}/byPage`, data)
}

/**
 * 根据实体ID查询详情
 * @param entityName
 * @param entityId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findById(entityName, entityId) {
    return request.get(`api/v1/system/${entityName}/${entityId}`)
}

/**
 * 根据用户分页查询系统公告
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByUserPage(entityName, data) {
    return request.post(`api/v1/system/${entityName}/byUserPage`, data)
}

/**
 * 根据实体ID发布系统公告
 * @param entityName
 * @param entityId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function changeEnableDistribute(entityName, entityId) {
    return request.get(`api/v1/system/${entityName}/distribute/${entityId}`)
}

/**
 * 查询所有
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findAll(entityName, data) {
    return request.post(`api/v1/system/${entityName}/all`, data)
}

/**
 * 电子签-模板查询
 * @param entityName
 * @param type
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByPageTSignTask(entityName, type, data) {
    return request.post(`api/v1/system/${entityName}/byPage/${type}`, data)
}

/**
 * 电子签-统计数据
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function analysis(entityName, data) {
    return request.post(`api/v1/system/${entityName}`, data)
}

/**
 * 电子签-根据权限查询配置列表
 * @param entityName
 * @returns {Promise<AxiosResponse<any>>}
 */
export function esignConfigList(entityName) {
    return request.post(`api/v1/system/${entityName}/config`)
}

/**
 * 电子签-标签类型
 * @param entityName
 * @param orgId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByTag(entityName, orgId) {
    return request.post(`api/v1/system/${entityName}/tag/${orgId}`)
}

/**
 * 电子签-业务类型
 * @param entityName
 * @param orgId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByType(entityName, orgId) {
    return request.post(`api/v1/system/${entityName}/type/${orgId}`)
}

/**
 * 电子签-管理页面连接
 * @param entityName
 * @param orgId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByManage(entityName, orgId) {
    return request.post(`api/v1/system/${entityName}/manage/${orgId}`)
}

/**
 * 电子签-查询详细信息
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByDto(entityName, data) {
    return request.post(`api/v1/system/${entityName}/detail`, data)
}

/**
 * 电子签-预览文件
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByPreview(entityName, data) {
    return request.post(`api/v1/system/${entityName}/preview`, data)
}

/**
 * 电子签-下载文件
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByDownload(entityName, data) {
    return request.post(`api/v1/system/${entityName}/download`, data)
}

/**
 * 电子签-管理编辑
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByManageEdit(entityName, data) {
    return request.post(`api/v1/system/${entityName}/manageEdit`, data)
}

/**
 * 电子签-签署
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function signTaskActor(entityName, data) {
    return request.post(`api/v1/system/${entityName}/actor`, data)
}

/**
 * 电子签-签署任务-操作[删除:delete, 撤销:cancel, 作废:abolish]
 * @param entityName
 * @param type
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function signTaskOperation(entityName, type, data) {
    return request.post(`api/v1/system/${entityName}/${type}`, data)
}

/**
 * 根据ID查询公众号类型配置
* @param configType
* @param entityId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findConfigTypeById(configType,entityId) {
    return request.get(`api/v1/system/${MP_CONFIG}/${configType}/${entityId}`)
}

/**
 * 更新公众号模板推送配置
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateTemplatePush(data) {
    return request.post(`api/v1/system/${MP_CONFIG}/templatePush`, data)
}

/**
 * 根据组织ID查询详情
 * @param entityName
 * @param orgId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByOrgId(entityName, orgId) {
    return request.get(`api/v1/system/${entityName}/organization/${orgId}`)
}

/**
 * 保存实体
 * @param entityName
 * @param data
 * @param isUpdate
 * @returns {Promise<AxiosResponse<any>>}
 */
export function saveEntity(entityName, data, isUpdate) {
    if (isUpdate) {
        return request.put(`api/v1/system/${entityName}`, data)
    }
    return request.post(`api/v1/system/${entityName}`, data)
}

/**
 * 根据实体ID删除
 * @param entityName
 * @param entityId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteById(entityName, entityId) {
    return request.delete(`api/v1/system/${entityName}/${entityId}`)
}

/**
 * 删除实体
 * @param entityName
 * @param entityId
 * @param timestamp
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteEntity(entityName, entityId, timestamp) {
    return request.delete(`api/v1/system/${entityName}/${entityId}/${timestamp}`)
}

/**
 * 修改实体启用状态
 * @param entityId
 * @param enabled
 * @param entityName
 * @returns {Promise<AxiosResponse<any>>}
 */
export function changeEnableState(entityId, enabled, entityName) {
    return request.get(`api/v1/system/${entityName}/enable/${entityId}/${enabled}`)
}

/**
 * 菜单树查询
 * @param data
 * @returns {*}
 */
export function queryTree(entityName, data) {
    return request.post(`api/v1/system/${entityName}/tree`, data)
}

/**
 * 查询所有分类
 * @param data
 * @returns {*}
 */
export function queryAllCategory(entityName) {
    return request.get(`api/v1/system/${entityName}/category`)
}

/**
 * 查询所有操作日志类型
 * @param data
 * @returns {*}
 */
export function queryAllLogTypes() {
    return request.get(`api/v1/system/${OPERATION_LOG}/types`)
}

/**
 * 查询电池报警类型
 * @param data
 * @returns {*}
 */
export function queryBatteryAlarmTypes() {
    return request.get('api/v1/system/alarmType/battery')
}

/**
 * 查询换电柜报警类型
 * @param data
 * @returns {*}
 */
export function queryCabinetAlarmTypes() {
    return request.get('api/v1/system/alarmType/cabinet')
}

/**
 * 查询电池指令类型
 * @param data
 * @returns {*}
 */
export function queryBatteryCmdTypes() {
    return request.get('api/v1/system/cmdType/battery')
}

/**
 * 查询换电柜指令类型
 * @param data
 * @returns {*}
 */
export function queryCabinetCmdTypes() {
    return request.get('api/v1/system/cmdType/cabinet')
}

/**
 * 查询车辆指令类型
 * @param data
 * @returns {*}
 */
export function queryVehicleCmdTypes() {
    return request.get('api/v1/system/cmdType/vehicle')
}

/**
 * 根据ID查询支付配置
 * @param entityName
 * @param entityId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findPayConfigById(entityId) {
    return request.get(`api/v1/system/${APP_CONFIG}/payConfig/${entityId}`)
}

/**
 * 根据APP配置ID查询
 * @param entityName
 * @param appConfigId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findByAppConfig(entityName, appConfigId) {
    return request.get(`api/v1/system/${entityName}/appConfig/${appConfigId}`)
}

/**
 * 更新支付配置
 * @param configType
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updatePayConfig(configType, data) {
    return request.post(`api/v1/system/${APP_CONFIG}/${configType}`, data)
}

/**
 * 查询平台配置
 * @returns {Promise<AxiosResponse<any>>}
 */
export function findPlatformConfig() {
    return request.get(`api/v1/system/${PLATFORM_CONFIG}`)
}

/**
 * 更新APP全局配置
 * @param configType
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateGlobalConfig(configType, data) {
    return request.post(`api/v1/system/${APP_CONFIG}/${configType}`, data)
}

/**
 * 修改发布状态
 * @param entityId
 * @param publishStatus
 * @param entityName
 * @returns {Promise<AxiosResponse<any>>}
 */
export function changePublishStatus(entityId, publishStatus, entityName) {
    return request.get(`api/v1/system/${entityName}/publishStatus/${entityId}/${publishStatus}`)
}

/**
 * 获取服务器信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export function serverInfo() {
    return request.get(`api/v1/system/ops/${SERVER_INFO}`)
}

/**
 * 数据清理
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function dataClear(data) {
    return request.post(`api/v1/system/ops/${DATA_CLEAR}`, data)
}

/**
 * 数据缓存
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function dataCache(data) {
    return request.post(`api/v1/system/ops/${DATA_CACHE}`, data)
}

/**
 * 查询租赁服务状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function rentalServiceStatus(data) {
    return request.get('api/v1/system/ops/rentalServiceStatus', data)
}

/**
 * 租赁服务控制
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function rentalServiceCtrl(data) {
    return request.post('api/v1/system/ops/rentalServiceCtrl', data)
}

/**
 * 线下支付购买人脸认证次数
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function purchaseFaceIdByOffline(data) {
    return request.post(`api/v1/system/${FACE_ID_RECHARGE}/purchaseByOffline`, data)
}

/**
 * 审核业务
 * @param entityName
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function auditBusiness(entityName, data) {
    return request.post(`api/v1/system/${entityName}/audit`, data)
}

/**
 * 更新AccessToken
 * @param entityName
 * @param mpId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateAccessToken(entityName, mpId) {
    return request.get(`api/v1/system/${entityName}/updateAccessToken/${mpId}`)
}

/**
 * 保存公告图片
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function saveUploadImg(data) {
    return request.post(`api/v1/system/${ANNOUNCEMENT}/uploadImg`, data)
}
